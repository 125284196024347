import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Center from './Center';

export default function Loading() {
  return (
    <Center>
      <CircularProgress />
    </Center>
  );
}
