import { atomFamily, selectorFamily } from "recoil";

const firebaseDocumentDataAtomFamily = atomFamily<any, string>({
  key: 'firebaseDocumentDataAtom',
  default: null
});

const firebaseDocumentDataSelectorFamily = selectorFamily<any, string>({
  key: 'firebaseDocumentDataSelector',
  get: (documentPath) => ({get}) => {
    const coinMarketCapData = get(firebaseDocumentDataAtomFamily(documentPath));
  
    if(coinMarketCapData == null ) {
      throw Promise.resolve();
    }

    return coinMarketCapData;
  },
  set: (documentPath )=>({set}, newValue)=>{
    set(firebaseDocumentDataAtomFamily(documentPath), newValue);
  }
});

export default firebaseDocumentDataSelectorFamily;