import { atom, selector, DefaultValue } from "recoil";
import firebase from '../firebase';

type firebaseUserIdAtomType = string | null | DefaultValue;

const firebaseUserIdAtom = atom<firebaseUserIdAtomType>({
  key: 'firebaseUserIdAtom',
  default: new DefaultValue(),
});

const firebaseUserIdSelector = selector<firebaseUserIdAtomType>({
  key: 'firebaseUserIdSelector',
  get: ({get}) => {
    const UserID = get(firebaseUserIdAtom);
    
    if(UserID === null || UserID instanceof DefaultValue) {
      return UserID;
    }

    const currentUser = firebase.auth().currentUser;

    if(UserID === currentUser?.uid) {
      return UserID
    }

    return null;
  },
  set: ({set}, newValue )=>{
    set(firebaseUserIdAtom, newValue);
  } 
});

export default firebaseUserIdSelector;