import React  from 'react';
import MenuBar from './MenuBar';
import Drawer from '@material-ui/core/Drawer';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Loading from '../shared/Loading';
import useDocSubscription from '../../hooks/useDocSubscription';


const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      position: "relative",
      zIndex:1000
    },
    drawerPaper: {
      width: drawerWidth,
    },
    contentContainer: {
      padding: theme.spacing(3),
      left: drawerWidth,
      flexGrow: 1,
      display: "flex",
      flexDirection: "column"
    },
    contentArea: {
      display: "flex",
      flexGrow: 1
    }
  }),
);

export default function AuthenticatedRoot() {
  useDocSubscription("market_data/assets");
  useDocSubscription("exchanges/binance");
  
  const classes = useStyles();

  return (<>
    <MenuBar />
    <div className = {classes.contentArea}>    
      <Drawer
          className = {classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
        <Toolbar />
        <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Container className={classes.contentContainer}>
        <Toolbar />
        <React.Suspense fallback={<Loading />}>
          {/* <Table /> */}
        </React.Suspense>
      </Container>
    </div>
  </>);
}



