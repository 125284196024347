import { useEffect } from 'react';
import firebase, { db } from '../firebase';
import { useSetRecoilState } from "recoil";
import firebaseUserIdAtom from "../atoms/firebaseUserIdAtom";

let subscription: firebase.Unsubscribe | null = null; 

export default function useAuthSubscription(): void {
  const setFirebaseUserId = useSetRecoilState(firebaseUserIdAtom);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    if(subscription !== null) return;
    subscription = firebase.auth().onAuthStateChanged(
      (user)=>{
        setFirebaseUserId(user?.uid ? user.uid : null);
        
        if(user?.uid) {
          db.doc(`users/${user.uid}`).set({
            lastLogin: firebase.firestore.Timestamp.now(),
          }, { merge: true });
        }
      }
    );
    return () => { subscription && subscription() }
  }, [setFirebaseUserId]);

}
