import React, { ReactElement } from 'react';
import Loading from './Loading';
import { useRecoilValue, DefaultValue } from "recoil";
import firebaseUserIdAtom from "../../atoms/firebaseUserIdAtom";

import { Route, Redirect, RouteProps } from "react-router-dom";

export default function ProtectedRoute({
  redirect, 
  fallback,
  children,
  ...routeProps
}: RouteProps & { redirect: string, fallback?: ReactElement }): ReactElement | null {
  const firebaseUser = useRecoilValue(firebaseUserIdAtom);
  
  if(firebaseUser instanceof DefaultValue) {
    return fallback || <Loading />;
  };

  return (
    <Route {...routeProps} render={({ location }) => {
      return firebaseUser !== null
        ? children
        : <Redirect to={{
            pathname: redirect,
            state: { from: location }
          }} />
    }} />
  );
}  


