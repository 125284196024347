import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import firebase from './firebase';
import { RecoilRoot } from 'recoil';
import { Global, css } from '@emotion/react';
import {BrowserRouter} from 'react-router-dom';

const GlobalStyles = css`
  html, body, #root {
    position:relative;
    min-height: 100%;
    display:flex;
    flex-grow: 1;
    flex-direction: column;
  }
`
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>
        <CssBaseline />
        <Global styles={GlobalStyles} />
        <App />
      </RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const analytics = firebase.analytics();
reportWebVitals((event)=>{
  const {name, delta, id} = event;

  analytics.logEvent( name, {
    event_category: 'Web Vitals',
    // The `id` value will be unique to the current page load. When sending
    // multiple values from the same page (e.g. for CLS), Google Analytics can
    // compute a total by grouping on this ID (note: requires `eventLabel` to
    // be a dimension in your report).
    event_label: id,
    // Google Analytics metrics must be integers, so the value is rounded.
    // For CLS the value is first multiplied by 1000 for greater precision
    // (note: increase the multiplier for greater precision if needed).
    value: Math.round(name === 'CLS' ? delta * 1000 : delta),
    // Use a non-interaction event to avoid affecting bounce rate.
    non_interaction: true,
  });
});
