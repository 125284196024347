import { useEffect } from 'react';
import firebase, {db} from '../firebase';
import { useSetRecoilState } from "recoil";
import firebaseDocumentDataAtomFamily from "../atoms/firebaseDocumentDataAtomFamily";

const subscriptions: {
  [documentPath: string] : firebase.Unsubscribe 
} = {}; 

export default function useDocSubscription<TDocumentType>(documentPath: string): void {
  const setDocumentData = useSetRecoilState<TDocumentType>(firebaseDocumentDataAtomFamily(documentPath));

  // Subscribe to firebase document and set the local state.
  useEffect(() => {
    if(subscriptions[documentPath] != null ) {
      return; 
    }
    subscriptions[documentPath] = db.doc(documentPath).onSnapshot((doc)=>{
      setDocumentData(doc.data() as TDocumentType);
    })
    return () => { subscriptions[documentPath] && subscriptions[documentPath]() }; 
  }, [setDocumentData, documentPath]);

}