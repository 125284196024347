import React, { ReactElement } from 'react';
import ProtectedRoute from './shared/ProtectedRoute';
import useAuthSubscription from "../hooks/useAuthSubscription";
import UnauthenticatedRoot from './unauthenticated/Root';
import AuthenticatedRoot from "./authenticated/Root"
import { Switch, Route, Redirect } from "react-router-dom";

export default function App(): ReactElement<any, any> | null {
  useAuthSubscription();

  return (
    <Switch>
      <Route path="/login"><UnauthenticatedRoot /></Route>
      <ProtectedRoute path="/dashboard" redirect="/login"><AuthenticatedRoot /></ProtectedRoute>
      <Redirect to="/dashboard" />
    </Switch>
  );
}