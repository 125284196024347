import firebase from 'firebase/app';
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyB9z_qGes9DjWVOSNI243Jz2u1KdEZFH3M",
  authDomain: "cryptoindex-app.firebaseapp.com",
  projectId: "cryptoindex-app",
  storageBucket: "cryptoindex-app.appspot.com",
  messagingSenderId: "18102532808",
  appId: "1:18102532808:web:e89066287dffa375b983fd",
  measurementId: "G-52G0GX3YBT"
};
  
export const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore(app);

export default firebase;