import React  from 'react';
import SignIn from './SignIn';

export default function UnauthenticatedRoot() {
  return (
    <SignIn />
  );
}



